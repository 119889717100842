import { Card } from "@/Components/ui/card";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import { Popover as ReactPopover, ArrowContainer } from "react-tiny-popover";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function MenuPopover({
    children,
    label = "Label",
    tip,
    bgColor = "#3f4652",
    textColor = "#ffffff",
    clickOutsideCapture = true,
    onClickOutside = null,
    transform = { top: 0 },
    triggerClass = "",
    positions = ["bottom", "right", "left", "top"],
}) {
    const [open, setOpen] = useState(false);
    const ref = useRef();
    return (
        <>
            <ReactTooltip
                id="menu-tools-tips"
                place="top"
                variant="dark"
                className="z-[10001]"
            />
            <ReactPopover
                ref={ref}
                isOpen={open}
                clickOutsideCapture={clickOutsideCapture}
                onClickOutside={
                    !onClickOutside ? () => setOpen(false) : onClickOutside
                }
                positions={positions}
                transform={transform}
                transformMode="relative"
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={bgColor}
                        arrowSize={10}
                        arrowStyle={{ opacity: 1 }}
                        className="popover-arrow-container"
                        arrowClassName="popover-arrow"
                    >
                        <Card style={{
                            backgroundColor: bgColor,
                            color: textColor,
                        }} className="flex flex-col min-h-[50px]  min-w-[200px] relative overflow-hidden !rounded-md justify-start items-start gap-1">
                            {children}
                        </Card>
                    </ArrowContainer>
                )}
            >
                <span
                    data-tooltip-id="menu-tools-tips"
                    data-tooltip-content={tip || ""}
                    data-tooltip-place="top"
                    onClick={() => setOpen(!open)}
                    className={cn("text-[1rem] cursor-pointer hover:text-cyan-300 text-primary", triggerClass)}
                >
                    {label}
                </span>
            </ReactPopover>
        </>
    );
}
